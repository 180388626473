export const get_user_role_parents = [
  {
    name: "Sales",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    name: "Purchase",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    name: "Payment",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    discount:false,
    purchase_price:true
  },
  {
    name: "Receipt",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    discount:false,
    purchase_price:true
  },
  {
    name: "Product",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true,
  },
  {
    name: "Contacts",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
  },
  {
    name: "Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    name: "Accounts",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    name: "Loyalty",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    name: "Inventory",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    name: "Finance",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    name: "Others",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
];

export const get_user_role_data = [
  {
    parent: "Sales",
    name: "Sales Order",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true,
  },
  {
    parent: "Sales",
    name: "Sales Invoice",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true,
  },
  {
    parent: "Sales",
    name: "Sales Return",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true,
  },
  {
    parent: "Sales",
    name: "Sales Estimate",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true,
  },
  {
    parent: "Purchase",
    name: "Purchase Order",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Purchase",
    name: "Purchase Invoice",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Purchase",
    name: "Purchase Return",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Purchase",
    name: "Purchase Estimate",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Payment",
    name: "Payment Voucher",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Receipt",
    name: "Receipt Voucher",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Product",
    name: "Product",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true,
  },
  {
    parent: "Product",
    name: "Brands",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Product",
    name: "Product Groups",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Product",
    name: "Product Category",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Contacts",
    name: "Customer",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Contacts",
    name: "Supplier",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Contacts",
    name: "Employee",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Trial Balance",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Profit And Loss Account",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Balance Sheet",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Outstanding Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Daily Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Cash Book",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Bank Book",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Receipt Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Payment Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Ledger Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Bill Wise Profit Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Sales Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Sales Summary Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Sales Register Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Sales Return Register Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Sales Integrated Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Purchase Integrated Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Purchase Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Purchase Order Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Purchase Register Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Purchase Return Register Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Supplier Vs Product Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Stock Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Stock Value Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Stock Ledger Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Product Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Product Analysis Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  // {
  //   parent: "Report",
  //   name: "Inventory Flow Report",
  //   view_permission: false,
  //   save_permission: false,
  //   edit_permission: false,
  //   delete_permission: false,
  //   print_permission: false,
  // },
  {
    parent: "Report",
    name: "Batch Wise Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Excess Stock Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Shortage Stock Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Opening Stock Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Tax Summary",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "VAT Return",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Sales VAT Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Purchase VAT Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "GSTR1 Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Sales GST Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Purchase GST Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Batch Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Accounts",
    name: "Account Group",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Accounts",
    name: "Account Ledger",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Accounts",
    name: "Banks",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Accounts",
    name: "Tax Category",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Loyalty",
    name: "Loyalty Customer",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Loyalty",
    name: "Loyalty Program",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Inventory",
    name: "Work Order",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Inventory",
    name: "Stock Transfer",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Inventory",
    name: "Opening Stock",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Inventory",
    name: "Stock Management",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Finance",
    name: "Journal",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Finance",
    name: "Expense",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Warehouse",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Route",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Units",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Price Category",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Transaction Types",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Bank Reconciliation",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Stock Transfer Register",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Expense Summary",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Quick Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Debtor Ageing Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Creditor Ageing Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Stock Order",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Manufacturing",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Sales Order Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Product Summary Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Faera Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Credit Note",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Debit Note",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Purchase BT",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "Sales BT",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Others",
    name: "BT Request",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Product",
    name: "Cost With Expense",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Product",
    name: "Average Cost",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Dashboard",
    name: "Container1",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Dashboard",
    name: "Container2",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Dashboard",
    name: "Container3",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Dashboard",
    name: "Container4",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Dashboard",
    name: "Container5",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Dashboard",
    name: "Container6",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Product Serialisation Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Branch Transfer Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Day Book",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Expense Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Bill Wise Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "VB Assist Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "VAT Report",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Report",
    name: "Bank Statement",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Settings",
    name: "Settings",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Settings",
    name: "FaeraSettings",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Settings",
    name: "POSSettings",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Settings",
    name: "Branches",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Settings",
    name: "TransactionNos",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Settings",
    name: "Users",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Settings",
    name: "UserRoles",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Settings",
    name: "ActivityLog",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Sales",
    name: "Sales History",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
  {
    parent: "Purchase",
    name: "Purchase History",
    view_permission: false,
    save_permission: false,
    edit_permission: false,
    delete_permission: false,
    print_permission: false,
    block_date: false,
    unit_price:false,
    discount:false,
    purchase_price:true
  },
];