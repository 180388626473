import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Skeleton } from "antd";

function RassassyHomePage() {
  const { company_id, branch_id, sr_id } = useParams();

  const [loading, setLoading] = useState(false);

  const [dataList, setDataList] = useState({
    categoryList: [],
    productList: [],
  });

  const [state, setState] = useState({
    selectedCategory: null,
    showMore: false,
  });

  function handleCategoryClick(data) {
    setState((prevState) => ({
      ...prevState,
      selectedCategory: data,
    }));
    productListAPi(data?.ProductGroupID);
  }

  function handleShowMoreButtonClick() {
    setState((prevState) => ({
      ...prevState,
      showMore: true,
    }));
  }

  function handleCategoryCardClick(data) {
    setState((prevState) => ({
      ...prevState,
      selectedCategory: data,
    }));
    productListAPi(data?.ProductGroupID);
    setState((prevState) => ({
      ...prevState,
      showMore: false,
    }));
  }

  function CategoryCard({ data }) {
    return (
      <div
        className={style.categoryCard}
        style={{
          backgroundColor:
            state.selectedCategory?.id === data?.id ? "#F25F29" : "white",
          border:
            state.selectedCategory?.id === data?.id
              ? "none"
              : ".5px solid #E3E3E3",
          color: state.selectedCategory?.id === data?.id ? "white" : "black",
        }}
        onClick={() => handleCategoryClick(data)}
      >
        <img
          src={data?.Image ? data?.Image : "/images/icons/non-product.svg"}
          alt=""
        />
        {data.GroupName}
      </div>
    );
  }

  function ProductCard({ data }) {
    return (
      <div className={style.productCard}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            style={{ width: data?.ProductImage ? "100%" : "70px" }}
            src={
              data?.ProductImage
                ? data?.ProductImage
                : `/images/icons/non-product.svg`
            }
            alt=""
          />
        </div>
        <h2>{data?.ProductName}</h2>
        <h3>{data?.Description}</h3>
        <h2>
          <span>{data.CurrencyName}</span>
          <span className={style.currency}>{data?.DefaultSalesPrice}</span>
        </h2>
      </div>
    );
  }

  function ProductCardSkeleton() {
    return (
      <div className={style.productCard}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Skeleton variant="rect" style={{ width: "100%", height: "150px" }} />
        </div>
      </div>
    );
  }

  function ProductCategoryCard({ data }) {
    return (
      <>
        <div
          className={style.productCard}
          onClick={() => handleCategoryCardClick(data)}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ width: data.Image ? "100%" : "70px" }}
              src={data.Image ? data.Image : "/images/icons/non-product.svg"}
              alt=""
            />
          </div>
          <h2>{data.GroupName}</h2>
        </div>
      </>
    );
  }

  let apiUrl;

  if (sr_id == 1) {
    apiUrl = "https://www.saudiserver.viknbooks.com/api/v11/";
  } else if (sr_id == "test") {
    apiUrl = "https://www.api.viknbooks.in/api/v11/";
  } else if (sr_id == "local") {
    apiUrl = "http://localhost:8002/api/v11/";
  } else {
    apiUrl = "https://www.api.viknbooks.com/api/v11/";
  }
  async function groupAPI() {
    try {
      const response = await axios.get(
        `${apiUrl}posholds/product-group-list/`,
        {
          params: {
            CompanyID: company_id,
            BranchID: branch_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const refs = useRef([]);

  const scrollToCard = (index) => {
    if (refs.current[index]) {
      refs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  async function ProductListAPI(groupID) {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}posholds/product-list/`, {
        params: {
          CompanyID: company_id,
          BranchID: branch_id,
          GroupID: groupID,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setLoading(false);
    }
  }

  async function productListAPi(groupID) {
    const productAPIResponse = await ProductListAPI(groupID);
    if (productAPIResponse?.StatusCode === 6000) {
      setDataList((prevState) => ({
        ...prevState,
        productList: productAPIResponse?.data,
      }));
    }
  }

  async function fetchData() {
    const groupAPIResponse = await groupAPI();
    if (groupAPIResponse?.StatusCode === 6000) {
      setDataList((prevState) => ({
        ...prevState,
        categoryList: groupAPIResponse?.data,
      }));
      setState((prevState) => ({
        ...prevState,
        selectedCategory: groupAPIResponse?.data[0],
      }));
      productListAPi(groupAPIResponse?.data[0]?.ProductGroupID);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const categoryIndex = dataList.categoryList.findIndex(
      (element) => element.id === state.selectedCategory.id
    );
    scrollToCard(categoryIndex);
  }, [state.selectedCategory]);

  return (
    <div className={style.mainContainer}>
      {!state.showMore ? (
        <>
          <div className={style.categoryContainer}>
            <div className={style.categoryHeadingContainer}>
              <h2>Category</h2>
              <div
                className={style.seeMoreButton}
                onClick={handleShowMoreButtonClick}
              >
                See more
              </div>
            </div>
            <div className={style.scrollingContainer}>
              {dataList?.categoryList?.map((item, index) => (
                <div
                  key={index}
                  ref={(el) => (refs.current[index] = el)} // Assign refs to each card
                >
                  <CategoryCard data={item} />
                </div>
              ))}
            </div>
          </div>
          {/* product section */}
          <div className={style.productContainer}>
            {loading ? (
              <>
                {Array.from({ length: 10 }).map((_, index) => (
                  <ProductCardSkeleton key={index} />
                ))}
              </>
            ) : (
              <>
                {dataList?.productList?.map((item, index) => (
                  <ProductCard data={item} />
                ))}
                {Array.from({ length: 10 }).map((_, index) => (
                  <div></div>
                ))}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={style.mainContainer}>
            <div className={style.productContainer}>
              {dataList?.categoryList?.map((item, index) => (
                <ProductCategoryCard data={item} />
              ))}
              {Array.from({ length: 10 }).map((_, index) => (
                <div></div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default RassassyHomePage;
